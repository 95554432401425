jQuery(function ($) {
  // slick
  $('.hero').slick({
    centerMode: true,
    variableWidth: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 700,
    infinite: true,
    arrows: true
  }).on('afterChange', function (e, slick, currentSlide, nextSlide) {
    switch (currentSlide) {
      case 0:
        $(this).slick('slickSetOption', 'autoplaySpeed', 10000);
        break;
      default:
        $(this).slick('slickSetOption', 'autoplaySpeed', 2000);
        break;
    }
  });
});